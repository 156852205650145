import "./App.css";
import backgroundLogin from "../src/assets/images/background_image.gif";
import cybertroopsLogo from "../src/assets/images/Logo.png";

function App() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        background: `url('${backgroundLogin}') #00000080`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        fontFamily: process.env.REACT_APP_FONT_FAMILY,
        backgroundBlendMode: "multiply",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 36,
      }}
    >
      <div className="logo-container">
        <img src={cybertroopsLogo} alt="logo" />
      </div>
      <div className="caption-bottom">
        Unlock the Power of Engagement, <br />
        Ignite Your Social Impact
      </div>
    </div>
  );
}

export default App;
